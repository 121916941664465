exports.components = {
  "component---src-pages-help-tsx": () => import("./../../../src/pages/help.tsx" /* webpackChunkName: "component---src-pages-help-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-buildings-commute-time-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/constraints-buildings-commute-time.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-buildings-commute-time-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-buildings-minimize-transfer-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/constraints-buildings-minimize-transfer.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-buildings-minimize-transfer-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-buildings-one-building-per-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/constraints-buildings-one-building-per-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-buildings-one-building-per-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-lessons-consecutive-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/constraints-lessons-consecutive.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-lessons-consecutive-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-lessons-evenly-spread-across-week-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/constraints-lessons-evenly-spread-across-week.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-lessons-evenly-spread-across-week-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-lessons-force-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/constraints-lessons-force-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-lessons-force-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-lessons-many-consecutive-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/constraints-lessons-many-consecutive.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-lessons-many-consecutive-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-lessons-max-two-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/constraints-lessons-max-two-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-lessons-max-two-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-lessons-more-two-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/constraints-lessons-more-two-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-lessons-more-two-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-lessons-not-consecutive-days-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/constraints-lessons-not-consecutive-days.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-lessons-not-consecutive-days-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-lessons-not-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/constraints-lessons-not-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-lessons-not-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-lessons-one-per-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/constraints-lessons-one-per-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-lessons-one-per-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-lessons-place-important-earlier-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/constraints-lessons-place-important-earlier.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-lessons-place-important-earlier-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-lessons-two-days-apart-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/constraints-lessons-two-days-apart.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-lessons-two-days-apart-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-teachers-minimize-gaps-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/constraints-teachers-minimize-gaps.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-teachers-minimize-gaps-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-teachers-minimize-working-days-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/constraints-teachers-minimize-working-days.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-constraints-teachers-minimize-working-days-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-divisions-multiple-grades-same-time-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/divisions-multiple-grades-same-time.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-divisions-multiple-grades-same-time-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-divisions-multiple-subgroups-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/divisions-multiple-subgroups.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-divisions-multiple-subgroups-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-divisions-same-time-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/divisions-same-time.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-divisions-same-time-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-lessons-add-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/lessons-add.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-lessons-add-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-lessons-allocation-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/lessons-allocation.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-lessons-allocation-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-lessons-divisions-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/lessons-divisions.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-lessons-divisions-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-lessons-edit-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/lessons-edit.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-lessons-edit-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-lessons-export-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/lessons-export.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-lessons-export-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-lessons-import-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/lessons-import.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-lessons-import-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-lessons-importance-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/lessons-importance.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-lessons-importance-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-lessons-multiple-classes-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/lessons-multiple-classes.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-lessons-multiple-classes-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-lessons-non-weekly-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/lessons-non-weekly.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-lessons-non-weekly-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-lessons-rooms-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/lessons-rooms.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-lessons-rooms-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-lessons-unavailable-slots-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/lessons-unavailable-slots.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-lessons-unavailable-slots-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-de-school-configuration-building-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/de/school-configuration-building.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-de-school-configuration-building-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-buildings-commute-time-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/constraints-buildings-commute-time.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-buildings-commute-time-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-buildings-minimize-transfer-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/constraints-buildings-minimize-transfer.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-buildings-minimize-transfer-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-buildings-one-building-per-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/constraints-buildings-one-building-per-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-buildings-one-building-per-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-lessons-consecutive-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/constraints-lessons-consecutive.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-lessons-consecutive-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-lessons-evenly-spread-across-week-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/constraints-lessons-evenly-spread-across-week.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-lessons-evenly-spread-across-week-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-lessons-force-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/constraints-lessons-force-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-lessons-force-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-lessons-many-consecutive-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/constraints-lessons-many-consecutive.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-lessons-many-consecutive-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-lessons-max-two-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/constraints-lessons-max-two-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-lessons-max-two-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-lessons-more-two-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/constraints-lessons-more-two-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-lessons-more-two-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-lessons-not-consecutive-days-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/constraints-lessons-not-consecutive-days.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-lessons-not-consecutive-days-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-lessons-not-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/constraints-lessons-not-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-lessons-not-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-lessons-one-per-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/constraints-lessons-one-per-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-lessons-one-per-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-lessons-place-important-earlier-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/constraints-lessons-place-important-earlier.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-lessons-place-important-earlier-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-lessons-two-days-apart-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/constraints-lessons-two-days-apart.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-lessons-two-days-apart-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-teachers-minimize-gaps-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/constraints-teachers-minimize-gaps.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-teachers-minimize-gaps-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-teachers-minimize-working-days-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/constraints-teachers-minimize-working-days.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-constraints-teachers-minimize-working-days-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-divisions-multiple-grades-same-time-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/divisions-multiple-grades-same-time.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-divisions-multiple-grades-same-time-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-divisions-multiple-subgroups-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/divisions-multiple-subgroups.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-divisions-multiple-subgroups-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-divisions-same-time-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/divisions-same-time.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-divisions-same-time-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-lessons-add-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/lessons-add.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-lessons-add-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-lessons-allocation-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/lessons-allocation.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-lessons-allocation-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-lessons-divisions-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/lessons-divisions.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-lessons-divisions-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-lessons-edit-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/lessons-edit.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-lessons-edit-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-lessons-export-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/lessons-export.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-lessons-export-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-lessons-import-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/lessons-import.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-lessons-import-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-lessons-importance-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/lessons-importance.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-lessons-importance-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-lessons-multiple-classes-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/lessons-multiple-classes.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-lessons-multiple-classes-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-lessons-non-weekly-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/lessons-non-weekly.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-lessons-non-weekly-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-lessons-rooms-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/lessons-rooms.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-lessons-rooms-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-lessons-unavailable-slots-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/lessons-unavailable-slots.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-lessons-unavailable-slots-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-en-school-configuration-building-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/en/school-configuration-building.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-en-school-configuration-building-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-buildings-commute-time-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/constraints-buildings-commute-time.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-buildings-commute-time-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-buildings-minimize-transfer-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/constraints-buildings-minimize-transfer.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-buildings-minimize-transfer-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-buildings-one-building-per-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/constraints-buildings-one-building-per-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-buildings-one-building-per-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-lessons-consecutive-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/constraints-lessons-consecutive.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-lessons-consecutive-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-lessons-evenly-spread-across-week-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/constraints-lessons-evenly-spread-across-week.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-lessons-evenly-spread-across-week-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-lessons-force-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/constraints-lessons-force-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-lessons-force-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-lessons-many-consecutive-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/constraints-lessons-many-consecutive.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-lessons-many-consecutive-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-lessons-max-two-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/constraints-lessons-max-two-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-lessons-max-two-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-lessons-more-two-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/constraints-lessons-more-two-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-lessons-more-two-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-lessons-not-consecutive-days-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/constraints-lessons-not-consecutive-days.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-lessons-not-consecutive-days-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-lessons-not-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/constraints-lessons-not-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-lessons-not-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-lessons-one-per-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/constraints-lessons-one-per-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-lessons-one-per-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-lessons-place-important-earlier-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/constraints-lessons-place-important-earlier.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-lessons-place-important-earlier-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-lessons-two-days-apart-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/constraints-lessons-two-days-apart.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-lessons-two-days-apart-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-teachers-minimize-gaps-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/constraints-teachers-minimize-gaps.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-teachers-minimize-gaps-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-teachers-minimize-working-days-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/constraints-teachers-minimize-working-days.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-constraints-teachers-minimize-working-days-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-divisions-multiple-grades-same-time-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/divisions-multiple-grades-same-time.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-divisions-multiple-grades-same-time-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-divisions-multiple-subgroups-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/divisions-multiple-subgroups.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-divisions-multiple-subgroups-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-divisions-same-time-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/divisions-same-time.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-divisions-same-time-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-lessons-add-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/lessons-add.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-lessons-add-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-lessons-allocation-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/lessons-allocation.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-lessons-allocation-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-lessons-divisions-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/lessons-divisions.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-lessons-divisions-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-lessons-edit-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/lessons-edit.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-lessons-edit-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-lessons-export-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/lessons-export.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-lessons-export-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-lessons-import-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/lessons-import.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-lessons-import-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-lessons-importance-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/lessons-importance.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-lessons-importance-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-lessons-multiple-classes-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/lessons-multiple-classes.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-lessons-multiple-classes-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-lessons-non-weekly-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/lessons-non-weekly.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-lessons-non-weekly-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-lessons-rooms-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/lessons-rooms.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-lessons-rooms-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-lessons-unavailable-slots-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/lessons-unavailable-slots.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-lessons-unavailable-slots-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-es-school-configuration-building-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/es/school-configuration-building.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-es-school-configuration-building-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-buildings-commute-time-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/constraints-buildings-commute-time.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-buildings-commute-time-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-buildings-minimize-transfer-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/constraints-buildings-minimize-transfer.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-buildings-minimize-transfer-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-buildings-one-building-per-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/constraints-buildings-one-building-per-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-buildings-one-building-per-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-lessons-consecutive-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/constraints-lessons-consecutive.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-lessons-consecutive-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-lessons-evenly-spread-across-week-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/constraints-lessons-evenly-spread-across-week.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-lessons-evenly-spread-across-week-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-lessons-force-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/constraints-lessons-force-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-lessons-force-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-lessons-many-consecutive-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/constraints-lessons-many-consecutive.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-lessons-many-consecutive-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-lessons-max-two-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/constraints-lessons-max-two-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-lessons-max-two-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-lessons-more-two-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/constraints-lessons-more-two-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-lessons-more-two-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-lessons-not-consecutive-days-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/constraints-lessons-not-consecutive-days.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-lessons-not-consecutive-days-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-lessons-not-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/constraints-lessons-not-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-lessons-not-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-lessons-one-per-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/constraints-lessons-one-per-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-lessons-one-per-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-lessons-place-important-earlier-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/constraints-lessons-place-important-earlier.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-lessons-place-important-earlier-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-lessons-two-days-apart-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/constraints-lessons-two-days-apart.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-lessons-two-days-apart-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-teachers-minimize-gaps-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/constraints-teachers-minimize-gaps.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-teachers-minimize-gaps-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-teachers-minimize-working-days-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/constraints-teachers-minimize-working-days.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-constraints-teachers-minimize-working-days-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-divisions-multiple-grades-same-time-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/divisions-multiple-grades-same-time.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-divisions-multiple-grades-same-time-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-divisions-multiple-subgroups-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/divisions-multiple-subgroups.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-divisions-multiple-subgroups-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-divisions-same-time-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/divisions-same-time.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-divisions-same-time-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-lessons-add-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/lessons-add.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-lessons-add-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-lessons-allocation-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/lessons-allocation.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-lessons-allocation-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-lessons-divisions-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/lessons-divisions.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-lessons-divisions-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-lessons-edit-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/lessons-edit.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-lessons-edit-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-lessons-export-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/lessons-export.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-lessons-export-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-lessons-import-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/lessons-import.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-lessons-import-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-lessons-importance-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/lessons-importance.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-lessons-importance-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-lessons-multiple-classes-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/lessons-multiple-classes.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-lessons-multiple-classes-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-lessons-non-weekly-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/lessons-non-weekly.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-lessons-non-weekly-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-lessons-rooms-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/lessons-rooms.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-lessons-rooms-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-lessons-unavailable-slots-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/lessons-unavailable-slots.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-lessons-unavailable-slots-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-fr-school-configuration-building-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/fr/school-configuration-building.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-fr-school-configuration-building-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-buildings-commute-time-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/constraints-buildings-commute-time.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-buildings-commute-time-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-buildings-minimize-transfer-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/constraints-buildings-minimize-transfer.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-buildings-minimize-transfer-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-buildings-one-building-per-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/constraints-buildings-one-building-per-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-buildings-one-building-per-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-lessons-consecutive-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/constraints-lessons-consecutive.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-lessons-consecutive-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-lessons-evenly-spread-across-week-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/constraints-lessons-evenly-spread-across-week.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-lessons-evenly-spread-across-week-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-lessons-force-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/constraints-lessons-force-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-lessons-force-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-lessons-many-consecutive-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/constraints-lessons-many-consecutive.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-lessons-many-consecutive-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-lessons-max-two-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/constraints-lessons-max-two-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-lessons-max-two-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-lessons-more-two-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/constraints-lessons-more-two-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-lessons-more-two-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-lessons-not-consecutive-days-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/constraints-lessons-not-consecutive-days.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-lessons-not-consecutive-days-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-lessons-not-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/constraints-lessons-not-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-lessons-not-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-lessons-one-per-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/constraints-lessons-one-per-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-lessons-one-per-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-lessons-place-important-earlier-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/constraints-lessons-place-important-earlier.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-lessons-place-important-earlier-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-lessons-two-days-apart-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/constraints-lessons-two-days-apart.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-lessons-two-days-apart-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-teachers-minimize-gaps-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/constraints-teachers-minimize-gaps.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-teachers-minimize-gaps-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-teachers-minimize-working-days-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/constraints-teachers-minimize-working-days.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-constraints-teachers-minimize-working-days-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-divisions-multiple-grades-same-time-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/divisions-multiple-grades-same-time.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-divisions-multiple-grades-same-time-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-divisions-multiple-subgroups-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/divisions-multiple-subgroups.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-divisions-multiple-subgroups-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-divisions-same-time-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/divisions-same-time.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-divisions-same-time-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-lessons-add-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/lessons-add.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-lessons-add-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-lessons-allocation-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/lessons-allocation.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-lessons-allocation-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-lessons-divisions-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/lessons-divisions.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-lessons-divisions-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-lessons-edit-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/lessons-edit.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-lessons-edit-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-lessons-export-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/lessons-export.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-lessons-export-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-lessons-import-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/lessons-import.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-lessons-import-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-lessons-importance-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/lessons-importance.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-lessons-importance-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-lessons-multiple-classes-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/lessons-multiple-classes.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-lessons-multiple-classes-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-lessons-non-weekly-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/lessons-non-weekly.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-lessons-non-weekly-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-lessons-rooms-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/lessons-rooms.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-lessons-rooms-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-lessons-unavailable-slots-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/lessons-unavailable-slots.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-lessons-unavailable-slots-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-it-school-configuration-building-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/it/school-configuration-building.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-it-school-configuration-building-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-buildings-commute-time-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/constraints-buildings-commute-time.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-buildings-commute-time-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-buildings-minimize-transfer-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/constraints-buildings-minimize-transfer.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-buildings-minimize-transfer-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-buildings-one-building-per-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/constraints-buildings-one-building-per-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-buildings-one-building-per-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-lessons-consecutive-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/constraints-lessons-consecutive.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-lessons-consecutive-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-lessons-evenly-spread-across-week-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/constraints-lessons-evenly-spread-across-week.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-lessons-evenly-spread-across-week-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-lessons-force-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/constraints-lessons-force-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-lessons-force-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-lessons-many-consecutive-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/constraints-lessons-many-consecutive.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-lessons-many-consecutive-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-lessons-max-two-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/constraints-lessons-max-two-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-lessons-max-two-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-lessons-more-two-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/constraints-lessons-more-two-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-lessons-more-two-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-lessons-not-consecutive-days-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/constraints-lessons-not-consecutive-days.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-lessons-not-consecutive-days-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-lessons-not-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/constraints-lessons-not-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-lessons-not-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-lessons-one-per-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/constraints-lessons-one-per-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-lessons-one-per-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-lessons-place-important-earlier-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/constraints-lessons-place-important-earlier.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-lessons-place-important-earlier-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-lessons-two-days-apart-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/constraints-lessons-two-days-apart.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-lessons-two-days-apart-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-teachers-minimize-gaps-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/constraints-teachers-minimize-gaps.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-teachers-minimize-gaps-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-teachers-minimize-working-days-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/constraints-teachers-minimize-working-days.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-constraints-teachers-minimize-working-days-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-divisions-multiple-grades-same-time-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/divisions-multiple-grades-same-time.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-divisions-multiple-grades-same-time-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-divisions-multiple-subgroups-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/divisions-multiple-subgroups.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-divisions-multiple-subgroups-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-divisions-same-time-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/divisions-same-time.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-divisions-same-time-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-lessons-add-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/lessons-add.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-lessons-add-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-lessons-allocation-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/lessons-allocation.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-lessons-allocation-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-lessons-divisions-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/lessons-divisions.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-lessons-divisions-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-lessons-edit-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/lessons-edit.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-lessons-edit-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-lessons-export-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/lessons-export.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-lessons-export-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-lessons-import-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/lessons-import.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-lessons-import-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-lessons-importance-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/lessons-importance.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-lessons-importance-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-lessons-multiple-classes-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/lessons-multiple-classes.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-lessons-multiple-classes-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-lessons-non-weekly-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/lessons-non-weekly.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-lessons-non-weekly-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-lessons-rooms-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/lessons-rooms.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-lessons-rooms-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-lessons-unavailable-slots-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/lessons-unavailable-slots.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-lessons-unavailable-slots-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-nl-school-configuration-building-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/nl/school-configuration-building.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-nl-school-configuration-building-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pl-divisions-multiple-grades-same-time-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pl/divisions-multiple-grades-same-time.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pl-divisions-multiple-grades-same-time-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pl-divisions-multiple-subgroups-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pl/divisions-multiple-subgroups.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pl-divisions-multiple-subgroups-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pl-divisions-same-time-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pl/divisions-same-time.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pl-divisions-same-time-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pl-lessons-add-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pl/lessons-add.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pl-lessons-add-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pl-lessons-allocation-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pl/lessons-allocation.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pl-lessons-allocation-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pl-lessons-divisions-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pl/lessons-divisions.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pl-lessons-divisions-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pl-lessons-edit-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pl/lessons-edit.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pl-lessons-edit-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pl-lessons-export-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pl/lessons-export.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pl-lessons-export-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pl-lessons-import-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pl/lessons-import.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pl-lessons-import-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pl-lessons-importance-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pl/lessons-importance.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pl-lessons-importance-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pl-lessons-multiple-classes-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pl/lessons-multiple-classes.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pl-lessons-multiple-classes-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pl-lessons-non-weekly-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pl/lessons-non-weekly.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pl-lessons-non-weekly-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pl-lessons-rooms-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pl/lessons-rooms.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pl-lessons-rooms-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pl-lessons-unavailable-slots-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pl/lessons-unavailable-slots.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pl-lessons-unavailable-slots-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pl-school-configuration-building-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pl/school-configuration-building.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pl-school-configuration-building-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-buildings-commute-time-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pt/constraints-buildings-commute-time.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-buildings-commute-time-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-buildings-minimize-transfer-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pt/constraints-buildings-minimize-transfer.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-buildings-minimize-transfer-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-buildings-one-building-per-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pt/constraints-buildings-one-building-per-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-buildings-one-building-per-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-lessons-consecutive-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pt/constraints-lessons-consecutive.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-lessons-consecutive-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-lessons-evenly-spread-across-week-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pt/constraints-lessons-evenly-spread-across-week.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-lessons-evenly-spread-across-week-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-lessons-force-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pt/constraints-lessons-force-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-lessons-force-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-lessons-many-consecutive-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pt/constraints-lessons-many-consecutive.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-lessons-many-consecutive-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-lessons-max-two-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pt/constraints-lessons-max-two-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-lessons-max-two-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-lessons-more-two-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pt/constraints-lessons-more-two-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-lessons-more-two-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-lessons-not-consecutive-days-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pt/constraints-lessons-not-consecutive-days.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-lessons-not-consecutive-days-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-lessons-not-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pt/constraints-lessons-not-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-lessons-not-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-lessons-one-per-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pt/constraints-lessons-one-per-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-lessons-one-per-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-lessons-place-important-earlier-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pt/constraints-lessons-place-important-earlier.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-lessons-place-important-earlier-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-lessons-two-days-apart-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pt/constraints-lessons-two-days-apart.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-lessons-two-days-apart-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-teachers-minimize-gaps-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pt/constraints-teachers-minimize-gaps.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-teachers-minimize-gaps-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-teachers-minimize-working-days-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pt/constraints-teachers-minimize-working-days.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pt-constraints-teachers-minimize-working-days-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pt-lessons-add-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pt/lessons-add.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pt-lessons-add-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pt-lessons-allocation-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pt/lessons-allocation.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pt-lessons-allocation-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pt-lessons-divisions-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pt/lessons-divisions.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pt-lessons-divisions-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pt-lessons-edit-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pt/lessons-edit.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pt-lessons-edit-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pt-lessons-export-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pt/lessons-export.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pt-lessons-export-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pt-lessons-import-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pt/lessons-import.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pt-lessons-import-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pt-lessons-importance-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pt/lessons-importance.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pt-lessons-importance-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pt-lessons-multiple-classes-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pt/lessons-multiple-classes.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pt-lessons-multiple-classes-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pt-lessons-non-weekly-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pt/lessons-non-weekly.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pt-lessons-non-weekly-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pt-lessons-rooms-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pt/lessons-rooms.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pt-lessons-rooms-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-pt-lessons-unavailable-slots-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/pt/lessons-unavailable-slots.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-pt-lessons-unavailable-slots-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-buildings-commute-time-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/constraints-buildings-commute-time.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-buildings-commute-time-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-buildings-minimize-transfer-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/constraints-buildings-minimize-transfer.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-buildings-minimize-transfer-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-buildings-one-building-per-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/constraints-buildings-one-building-per-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-buildings-one-building-per-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-lessons-consecutive-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/constraints-lessons-consecutive.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-lessons-consecutive-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-lessons-evenly-spread-across-week-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/constraints-lessons-evenly-spread-across-week.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-lessons-evenly-spread-across-week-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-lessons-force-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/constraints-lessons-force-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-lessons-force-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-lessons-many-consecutive-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/constraints-lessons-many-consecutive.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-lessons-many-consecutive-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-lessons-max-two-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/constraints-lessons-max-two-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-lessons-max-two-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-lessons-more-two-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/constraints-lessons-more-two-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-lessons-more-two-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-lessons-not-consecutive-days-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/constraints-lessons-not-consecutive-days.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-lessons-not-consecutive-days-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-lessons-not-same-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/constraints-lessons-not-same-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-lessons-not-same-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-lessons-one-per-day-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/constraints-lessons-one-per-day.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-lessons-one-per-day-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-lessons-place-important-earlier-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/constraints-lessons-place-important-earlier.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-lessons-place-important-earlier-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-lessons-two-days-apart-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/constraints-lessons-two-days-apart.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-lessons-two-days-apart-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-teachers-minimize-gaps-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/constraints-teachers-minimize-gaps.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-teachers-minimize-gaps-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-teachers-minimize-working-days-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/constraints-teachers-minimize-working-days.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-constraints-teachers-minimize-working-days-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-divisions-multiple-grades-same-time-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/divisions-multiple-grades-same-time.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-divisions-multiple-grades-same-time-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-divisions-multiple-subgroups-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/divisions-multiple-subgroups.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-divisions-multiple-subgroups-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-divisions-same-time-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/divisions-same-time.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-divisions-same-time-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-lessons-add-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/lessons-add.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-lessons-add-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-lessons-allocation-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/lessons-allocation.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-lessons-allocation-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-lessons-divisions-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/lessons-divisions.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-lessons-divisions-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-lessons-edit-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/lessons-edit.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-lessons-edit-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-lessons-export-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/lessons-export.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-lessons-export-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-lessons-import-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/lessons-import.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-lessons-import-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-lessons-importance-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/lessons-importance.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-lessons-importance-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-lessons-multiple-classes-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/lessons-multiple-classes.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-lessons-multiple-classes-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-lessons-non-weekly-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/lessons-non-weekly.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-lessons-non-weekly-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-lessons-rooms-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/lessons-rooms.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-lessons-rooms-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-lessons-unavailable-slots-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/lessons-unavailable-slots.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-lessons-unavailable-slots-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-ro-school-configuration-building-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/ro/school-configuration-building.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-ro-school-configuration-building-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-tr-divisions-multiple-grades-same-time-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/tr/divisions-multiple-grades-same-time.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-tr-divisions-multiple-grades-same-time-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-tr-divisions-multiple-subgroups-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/tr/divisions-multiple-subgroups.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-tr-divisions-multiple-subgroups-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-tr-divisions-same-time-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/tr/divisions-same-time.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-tr-divisions-same-time-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-tr-lessons-add-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/tr/lessons-add.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-tr-lessons-add-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-tr-lessons-allocation-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/tr/lessons-allocation.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-tr-lessons-allocation-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-tr-lessons-divisions-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/tr/lessons-divisions.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-tr-lessons-divisions-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-tr-lessons-edit-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/tr/lessons-edit.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-tr-lessons-edit-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-tr-lessons-export-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/tr/lessons-export.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-tr-lessons-export-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-tr-lessons-import-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/tr/lessons-import.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-tr-lessons-import-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-tr-lessons-importance-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/tr/lessons-importance.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-tr-lessons-importance-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-tr-lessons-multiple-classes-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/tr/lessons-multiple-classes.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-tr-lessons-multiple-classes-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-tr-lessons-non-weekly-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/tr/lessons-non-weekly.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-tr-lessons-non-weekly-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-tr-lessons-rooms-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/tr/lessons-rooms.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-tr-lessons-rooms-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-tr-lessons-unavailable-slots-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/tr/lessons-unavailable-slots.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-tr-lessons-unavailable-slots-mdx" */),
  "component---src-templates-help-tsx-content-file-path-content-help-tr-school-configuration-building-mdx": () => import("./../../../src/templates/help.tsx?__contentFilePath=/builds/softix-projects/horarium/presentation-site/content/help/tr/school-configuration-building.mdx" /* webpackChunkName: "component---src-templates-help-tsx-content-file-path-content-help-tr-school-configuration-building-mdx" */)
}

